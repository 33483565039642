@media print {
    @page {
        size: letter portrait; 
        margin: 1in;
    }
    .cover-page {
        page-break-after: always;
        display: block;
    }
    .page-break {
        page-break-before: always;
    }
    body {
        margin: 0;
        padding: 0;
    }
    .page-break:last-of-type {
        page-break-after: avoid;
    }
    .paddingTop {
        padding-top: 1rem;
    }
    .note-page {
        page-break-before: always;
    }
    .note-content {
        page-break-inside: avoid;
    }
}

@media screen {
    .cover-page {
        display: none;
    }
    .note-page {
        display: block;
        visibility: hidden;
    }
}
