.custom-button-btn {
    background-color: #282560;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.custom-button-btn:hover {
    background-color: #1e1b44;
}

.table-responsive {
    overflow-x: auto;
}

.table-container {
    border-collapse: collapse;
    width: 100%;
    background-color: white;
    border-radius: 5px;
    overflow: hidden;
}

.table-heading {
    font-family: sans-serif;
    background-color: #3a3d89;
    border-radius: 8px;
    color: #ffff;
    font-size: 1.1rem;
    font-weight: normal;
    position: sticky;
    top: '50vh';
    z-index: 10;
}


.th,
.td {
    padding: 0.5rem 1.2rem;
    font-size: 0.8rem;
}

.td {
    border-bottom: 1px solid #ccc;
}

.data-row:hover {
    background-color: #f4f4f4;
    transition: background-color 0.3s ease;
}